import React, { useState, useContext, useEffect, useCallback } from "react";
import * as Yup from "yup";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import { bannerService } from "services/bannerService";
import { appConfigService } from "services/appConfigService.js";
import { provisioningService } from "../../../services/provisioningServices.js";

import {
  FormControl,
  FormGroup,
  FormControlLabel,
  FormLabel,
  Switch,
  Box,
  withStyles,
} from "@material-ui/core";

export default function AppConfigMappingForm({
  values,
  //appType,  
  handleSubmit,
  page  

}) {
  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);  
  const [app_Type,setApp_Type]=useState("");
  const [toggle,setToggle] = React.useState(false);
  const [tempName, setTempName] = useState([]);
  const [originalTempName, setOriginalTempName] = useState([]);
  const { dispatch: errorDispatch } = useContext(errorContext);

  useEffect(() => {
      bannerService.getBannerOptions('APP_TYPE').then(data => {
        if (data) {
          setApp_Type(data);
        }
      })
    },[]);

    useEffect(() => {
        provisioningService.verifyOtpNew('1313131313').then((res) => {
          const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
          const organizationId = JSON.parse(localStorage.getItem("orgId"));      
          //getFetchTag();
          getTemplateNames();
        })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });
          });
      }, []); 

      function getTemplateNames() {
          appConfigService.getAppConfigList().then((data) => {
            setTempName(data);            
          })
          .catch(error => {
            errorDispatch({
              type: "ERROR",
              error
            });            
          });
        }

    const handleToggle = (event) => {
      setToggle(!toggle)
      values[event.target.name] = !values[event.target.name]
      console.log("Toggles: ", values[event.target.name]);
      console.log("Toggles: ", event.target.name);
    }   

    values.isEnabled = toggle;    

  let validations = {
    
    appType: Yup.string().required(t("appConfigForm_New_Validation_appType")),    
    orgId: Yup.string()
              .required(t("appConfigMappingForm_Validation_orgId"))
              .matches(/^[0-9]+$/, "Must be positive integer"),
            //   .test('is-positive', 'Must be greater than 0', value => {
            //   return value ? parseInt(value, 10) > 0 : false;
            // }),
    templateName: Yup.string().required(t("appConfigMappingForm_Validation_templateName")),
    farmerId: Yup.string().matches(/^[0-9]+$/, "Must be positive integer")
  }; 

  const fields = [      
    {
        type: "custom",
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        },
        component: ({}) => (
          <FormControlLabel 
            control={
                <Switch checked={values.isEnabled} onChange={handleToggle} name="isEnabled" 
                 value={values.isEnabled} />
            }
            label={t("appConfigMapping_Form_isEnable_Label")}
            labelPlacement="top"        
          />
        )
      },
      {
        type: "select",
        name: "appType",      
        label: t("appConfigForm_New_appType"),
        placeholder: t("appConfigForm_New_appType"),
        option:
        app_Type.length > 0
            ? app_Type.map(app_Type => ({
                value: app_Type,
                label: app_Type
              }))
            : [],
        grid: {
          xs: 12,
          sm: 3,
          className: "mb-2"
        }, 
      },
      {},
      {
        type: "number",
        name: "orgId",
        label: t("appConfigMapping_Form_orgId"),
        placeholder: t("appConfigMapping_Form_orgId"),
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        }
      },
      {
        type: "select",
        name: "templateName",
        label: t("appConfigMapping_Form_templateName"),
        placeholder: t("appConfigMapping_Form_templateName"),
        option:
        tempName.length > 0
            ? tempName.map(tempName => ({
                value: tempName,
                label: tempName
              }))
            : [],
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        }
      },
      {
        type: "text",
        name: "matchingTags",
        label: t("appConfigMapping_Form_matchingTags"),
        placeholder: t("appConfigMapping_Form_matchingTags"),
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        }
      },
      {
        type: "number",
        name: "farmerId",
        label: t("appConfigMapping_Form_farmerId"),
        placeholder: t("appConfigMapping_Form_farmerId"),
        grid: {
          xs: 12,
          sm: 3,
          className: ""
        }
      },
      {}
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="App Configuration Mapping"
          // breadcrumbs={[
          //   { label: "Dashboard", link: "/dashboard" },
          //   { label: "Configuration" },
          //   { label: "App Configuration" }
          // ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        <Grid item>
          {loadedPage == "add" &&
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              enableReinitialize
              validateOnChange={true}              
              
              onSubmit={(values, { resetForm, setSubmitting }) => {
              console.log('onSubmit',values.isEnabled, values);
              handleSubmit(values, resetForm, setSubmitting);
              }}

              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          }
        </Grid>
      </Paper>      
    </Grid>
  );
}
