import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import { appConfigService } from "services/appConfigService.js";
import { errorContext } from "context/errorContext";
import { provisioningService } from "../../../services/provisioningServices.js";
import AppConfigFormNew from "./AppConfigFormNew.js";


export default function CreateAppConfigNew() {
  const organizationName = JSON.parse(localStorage.getItem("currentUser")).organizationName;
  const organizationId = JSON.parse(localStorage.getItem("orgId"));
  const history = useHistory();
  
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createFailure, setCreateFailure] = React.useState(false);
  const [failureMsg, setFailureMsg] = React.useState("");
  const { dispatch: errorDispatch } = useContext(errorContext);    
  
  const hideAlert = (event) => {
    setCreateSuccess(false);
    setCreateFailure(false);
  } 

  // to initialize the values when create appConfig form loads first time
  let values = {
    key: "",    
    value:"",    
    defaultValue:"", 
    appType: ""       
  };

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));      
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);    
 
  async function handleSubmit(values, resetForm, setSubmitting) {   
    if (values.appType === "SMART_FARMS") {
      values.appType = "smartfarms";
    } 
    console.log(values);    
    try {      
      const repeat = null;
      const result = await appConfigService.AppConfigCreateNew(values);
      console.log("Result:============= ", result);
      if(result.data === null && result.message && result.status === "failure"){        
        setCreateFailure(true);
        setFailureMsg(result.message);
        setSubmitting(false);
      }else{

        try {
          resetForm();
          setCreateSuccess(true);
          //resetForm(); 
          setTimeout(() => {           
            history.push("/configuration/appConfigNew_list");
          }, 3000); 
          
        } catch (e) {
          //console.error(e);
          setCreateFailure(true);
          setFailureMsg(e.error.message);
          setSubmitting(false);
        }

      }
    } catch (e) {
      console.error(e);
    }
    
  }

  // Calling the create appConfig form with values(to initialize),
  return (
    <>
      <AppConfigFormNew 
        page="add"
        values={values}               
        handleSubmit={handleSubmit}     
        //appType={appType}     
      />

      {createSuccess ? 
        <SweetAlert success
          title="App Configuration Created Successfully"
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          timeout={2000}
        ></SweetAlert> 
      : null}

      {createFailure ? 
        <SweetAlert danger
          title={failureMsg}
          onConfirm={hideAlert}
          //disabled={ disableOkBtn ? true : false }
          onCancel={hideAlert}
          //timeout={3000}
        ></SweetAlert> 
      : null}
      
    </>
  );
}
