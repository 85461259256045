import React, { useEffect, useContext, useState, useMemo } from "react";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//import Table from "components/common/table/ControlledPaginationTable";
import Table from "components/common/table/DynamicPaginationTable2.js";
import PageHeader from "../../../components/layout/PageHeader.js";

import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import { bannerService } from "services/bannerService";
import { appConfigService } from "services/appConfigService.js";

const appConfigList = props => {

  const { dispatch: errorDispatch } = useContext(errorContext);  
  const [fetchTag, setFetchTag] = useState([]);
  const [status, setStatus] = useState("loading");
  const [startDate] = React.useState(addDays(new Date(), -2));
  const [endDate] = React.useState(new Date());
  const [fetchTagStatus, setFetchTagStatus] = useState("loading");  
  const dispatch = useDispatch();
  const createError = useState({});
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("currentUser")); 

  const [tempName, setTempName] = useState([]);
  const [originalTempName, setOriginalTempName] = useState([]); 
  const [tempNameAftDelete, setTempNameAftDelete] = useState([]);  
  const [tempStatus, setTempStatus] = useState("");

  const { t } = useTranslation();
  const formikForm = React.useRef(null);

  function getFetchTag() {
    bannerService.getFetchTag().then((data) => {
      setFetchTag(data.fetchTags);   // postman key value also passing here
      setFetchTagStatus("success");
      setStatus("success");
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }

  function getTemplateNames() {
    appConfigService.getAppConfigList().then((data) => {
      setOriginalTempName(data);
      // Format each item as an object
      const formattedData = data.map(item => ({ templateNames: item }));
      setTempName(formattedData);
  
      // Create a string of objects separated by commas
      const formattedString = formattedData.map(item => JSON.stringify(item)).join(", ");
  
      // Log the formatted string of objects
      console.log(formattedString);      
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
      setStatus("failed");
    });
  }

  // function getTemplateNamesAfterDelete() {
  //     appConfigService.getAppConfigList().then((data) => {      
  //     setTempNameAftDelete(data);      
  //     console.log("getTemplateNamesAfterDelete",data);
  //   })
  //   .catch(error => {
  //     errorDispatch({
  //       type: "ERROR",
  //       error
  //     });
  //     setStatus("failed");
  //   });
  // }

  // function deleteTemplateName(tempNameDelete){
  //   appConfigService.deleteTempName(tempNameDelete).then(() => {  
  //   })
  //   .catch(error => {
  //     errorDispatch({
  //       type: "ERROR",
  //       error
  //     });
  //     setStatus("failed");
  //   });
  //   getTemplateNamesAfterDelete();
  // }

  function deleteTemplateName(tempNameDelete) {
    appConfigService.deleteTempName(tempNameDelete)
      .then(() => {
        // Do nothing on success
        //getTemplateNamesAfterDelete();
        getTemplateNames();
      })
      .catch(error => {        
        if (error.status === 404) {
          setTempStatus("Template is mapped to some configs");
        }
        else
        {
          setTempStatus("");
        }          
      })
      .finally(() => {        
        //getTemplateNamesAfterDelete();
        getTemplateNames();       
      });
  }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));      
      getFetchTag();
      getTemplateNames();
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []); 

  function handleDeleteRow(rowIndex) {
    
    const updatedData = tempName.filter((_, index) => index !== rowIndex); // Remove the row based on index
    setTempName(updatedData); // Update the state to reflect the deleted row

    const rowValue = originalTempName[rowIndex]; // Get the value of the row at the specified index
    console.log('Row to be deleted:', rowValue); // Log or perform any actions with this value

    deleteTemplateName(rowValue);    
    
  }  

  const columns = React.useMemo(
    () => [
      {
        Header: ("Template Name"),
        accessor: "templateNames"
      },
      {
        Header: t("appConfig_List_Delete_Label"),
        Cell: ({ row }) => {
          return (            
            <IconButton onClick={() => handleDeleteRow(row.index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </IconButton>            
          );
        }
      }      
    ],    
    [tempName]
  );

  //const data = React.useMemo(() => [...tempName]);
  const data = React.useMemo(() => tempName, [tempName, tempNameAftDelete]);  

  const dropitems = [
    {
      item: (
        <Link to="/configuration/appConfig_create">
         {t("appConfig_Add_Label")}
        </Link>
      )
    }
  ];

  return ( 

  <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="Template List"
          // breadcrumbs={[
          //   { label: "Dashboard", link: "/dashboard" },
          //   { label: "Configuration" },
          //   { label: "Template List" }
          // ]}
        />        

        {tempStatus !== "" && 
          <div style={{ color: 'red', whiteSpace: 'nowrap', marginLeft: "350px", marginTop: "20px" }}>
        {tempStatus}
          </div>
        }
        
      </Grid>
      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
        
      >
      <Table    
        columns={columns}     
        data={data}
        needDropdown={true}
        dropItems={dropitems}
        //hidePagination={true}
      /> 
      </Paper>      

    </Grid>
  );
};

export default appConfigList;
