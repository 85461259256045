import React, { lazy } from "react";

import { HashRouter as Router, Route, Switch } from "react-router-dom";

import { DashboardProvider } from "context/dashboardContext";

import SiteWrapper from "./components/layout/SiteWrapper";
import TotalSite from "./page-containers/ConfigurationPages/Site/SitesList";
import TotalSite2 from "./page-containers/ConfigurationPages/Site/SitesList2";
import OrganizationList from "./page-containers/ConfigurationPages/Organization/OrganizationList";
import UserList from "./page-containers/ConfigurationPages/User/UserList";
// import TotalBMC from "./page-containers/OtherPages/TotalBMC";
import TotalBMC1 from "./page-containers/OtherPages/TotalBMC1";
// import TotalBMC2 from "./page-containers/OtherPages/TotalBMC2";
import TotalBMC3 from "./page-containers/OtherPages/TotalBMC3";
import TotalLiveBMC from "./page-containers/OtherPages/TotalLiveBMC.js";
import AllAlerts from "./page-containers/OtherPages/AllAlerts";
import RoleList from "./page-containers/ConfigurationPages/Role/RoleList";
import Organization from "./page-containers/ConfigurationPages/Organization/Organization";
import ImportSite from "./page-containers/ConfigurationPages/Site/ImportSite";
import ImportAlert from "./page-containers/ConfigurationPages/Alert/ImportAlert";
import SiteDetails from "./page-containers/OtherPages/SiteDetails";
import PrivateRoute from "./components/Auth/PrivateRoute";
import SpinningLoader from "./components/common/elements/SpinningLoader";
import FormSample from "page-containers/OtherPages/FormFieldSamples";
import OrgSelectpage from "page-containers/OtherPages/OrgSelectpage";
import OrganizationProfile from "./page-containers/Profile/OrganizationProfile";
import SubOrganization from "./page-containers/ConfigurationPages/Organization/SuborganizationList";
import Profile from "./page-containers/Profile/UserProfile";
import CreateSite from "./page-containers/ConfigurationPages/Site/CreateSite";
import EditSite from "page-containers/ConfigurationPages/Site/EditSite";
import CreateUser from "page-containers/ConfigurationPages/User/CreateUser";
import EditUser from "page-containers/ConfigurationPages/User/EditUser";
import ChangePassword from "page-containers/ConfigurationPages/User/ChangePassword";
import SiteDetailsByUuid from "page-containers/Dashboard/components/SiteDetailsByUuid";
import ReportTabs from "page-containers/ConfigurationPages/Reports/ReportTabs";
import CriticalAlerts from "page-containers/OtherPages/CriticalAlerts";
import InfoAlerts from "page-containers/OtherPages/InfoAlerts";
import Error from "page-containers/ErrorPages/Error";
import Error500 from "page-containers/ErrorPages/Error500";
import Error503 from "page-containers/ErrorPages/Error503";
import Error401 from "page-containers/ErrorPages/Error401";
import Error403 from "page-containers/ErrorPages/Error403";
import ErrorWrapper from "page-containers/ErrorPages/ErrorWrapper";
import Error404 from "page-containers/ErrorPages/Error404";
import NoData from "page-containers/ErrorPages/NoData";
import ChecklistTemplateList from "./page-containers/ConfigurationPages/PreventiveMaintenance/PreventiveTemplatesList";
import CreateChecklistTemplate from "page-containers/ConfigurationPages/PreventiveMaintenance/CreateChecklistTemplate";
import EditChecklistTemplate from "page-containers/ConfigurationPages/PreventiveMaintenance/EditChecklistTemplate";

import LoanInsuranceList from "./page-containers/ConfigurationPages/LoanInsurance/LoanInsuranceList";
import CreateLoanInsurance from "page-containers/ConfigurationPages/LoanInsurance/CreateLoanInsurance";
import EditLoanInsurance from "page-containers/ConfigurationPages/LoanInsurance/EditLoanInsurance";

import RetailerList from "./page-containers/ConfigurationPages/Retailer/RetailerList";
import EditRetailer from "./page-containers/ConfigurationPages/Retailer/EditRetailer";
import ImportRetailer1 from "./page-containers/ConfigurationPages/Retailer/ImportRetailer1";

import AgentList from "./page-containers/ConfigurationPages/Agent/AgentList";
import CreateAgent from "page-containers/ConfigurationPages/Agent/CreateAgent";
import EditAgent from "page-containers/ConfigurationPages/Agent/EditAgent";
import AgentAssociation from "./page-containers/ConfigurationPages/Agent/AgentAssociation";

import ImportCOD from "./page-containers/ConfigurationPages/COD/ImportCOD";
import CODList from "./page-containers/ConfigurationPages/COD/CODList";

import AgentPaymentList from "./page-containers/ConfigurationPages/AgentPayment/AgentPaymentList";
import ImportAgentPayment from "./page-containers/ConfigurationPages/AgentPayment/ImportAgentPayment";

import Map from "components/Map";
import RatingGraph from "page-containers/OtherPages/RatingGraph";
import Dashboard from "./page-containers/Dashboard/Dashboard";
import PreventiveMaintenanceList from "page-containers/PreventiveMaintenance/PreventiveMaintenanceList";
import RealTimeChart from "page-containers/Dashboard/RealTimeChart";
import viewRecordDetails from "page-containers/PreventiveMaintenance/ViewRecordDetails";
import Faq from "./components/Faq";
import RenderIf from "components/Auth/RenderIf";
import DeviceData from "page-containers/OtherPages/DeviceData";
import ratingTemplate from "page-containers/ConfigurationPages/Analytics/RatingTemplate";
import analyticBmcRating from "page-containers/ConfigurationPages/Analytics/AnalyticsBmcRating";
import analyticBmcGraph from "page-containers/ConfigurationPages/Analytics/AnalyticsBmcGraph";
import AlertsSearchAll from "./page-containers/OtherPages/AlertsSearchAll";
import { useKeycloak } from "@react-keycloak/web";
import TotalNotification from "./page-containers/ConfigurationPages/Notification/NotificationList";
import CreateNotification from "./page-containers/ConfigurationPages/Notification/CreateNotification";
import EditNotification from "./page-containers/ConfigurationPages/Notification/EditNotification";
import CreateProduct from "./page-containers/ConfigurationPages/Product/CreateProduct";
import ProductList from "./page-containers/ConfigurationPages/Product/ProductList";
import EditProduct from "./page-containers/ConfigurationPages/Product/EditProduct";
import viewProd from "./page-containers/ConfigurationPages/Product/viewProduct";
import Approve from "./page-containers/ConfigurationPages/Approve/ApproveList";
import ViewCategory from "./page-containers/ConfigurationPages/Approve/ViewCategory";
import ViewProduct from "./page-containers/ConfigurationPages/Approve/ViewProduct";
import ViewPrice from "./page-containers/ConfigurationPages/Approve/ViewPrice";
import CategoryTreeDisplay from "./page-containers/ConfigurationPages/Category/CategoryTreeDisplay";
import FarmerAssociation from "./page-containers/ConfigurationPages/FarmerAssociation/FarmerAssociation";
import Criteria from "./page-containers/ConfigurationPages/Criteria/CriteriaList";
import EditCriteriaForDairyFarmer from "./page-containers/ConfigurationPages/Criteria/EditCriteriaForDairyFarmer";
import EditCriteriaForSelfRegFarmer from "./page-containers/ConfigurationPages/Criteria/EditCriteriaForSelfRegFarmer";

import AgentCriteria from "./page-containers/ConfigurationPages/AgentCriteria/AgentCriteriaList";
import EditAgentCriteria from "./page-containers/ConfigurationPages/AgentCriteria/EditAgentCriteria";
import CustomerGroup from "./page-containers/ConfigurationPages/CustomerGroup/CustomerGroupList";
import CreateCustomerGroup from "./page-containers/ConfigurationPages/CustomerGroup/CreateCustomerGroup";
import CreatePBP from "page-containers/ConfigurationPages/PBP/CreatePBP";
import pbpList from "page-containers/ConfigurationPages/PBP/pbpList";

import RetailerAgentList from "./page-containers/ConfigurationPages/RetailerAgent/RetailerAgentList";
import AssociateRetailerAgent from "./page-containers/ConfigurationPages/RetailerAgent/AssociateRetailerAgent";

import RetailerAgentCriteria from "./page-containers/ConfigurationPages/RetailerAgentCriteria/RetailerAgentCriteriaList";
import EditRetailerAgentCriteria from "./page-containers/ConfigurationPages/RetailerAgentCriteria/EditRetailerAgentCriteria";

import ItemLevelConfiguration from "./page-containers/ConfigurationPages/ItemLevelConfiguration/ItemLevelConfiguration";

import WarehouseAssociate from "./page-containers/ConfigurationPages/Warehouse/WarehouseAssociate";
import WarehouseList from "page-containers/ConfigurationPages/Warehouse/WareHouseList";
import WarehouseEdit from "page-containers/ConfigurationPages/Warehouse/EditWarehouse";
import WarehouseAssociateList from "page-containers/ConfigurationPages/Warehouse/WarehouseAssociateList";
import ImportWarehouse from "./page-containers/ConfigurationPages/Warehouse/ImportWarehouse";

import CreateBanner from "page-containers/ConfigurationPages/Banner/CreateBanner";
import bannerList from "page-containers/ConfigurationPages/Banner/bannerList";
import articleList from "page-containers/ConfigurationPages/Article/articleList";
import CreateArticle from "page-containers/ConfigurationPages/Article/CreateArticle";
import articleListHierarchy from "page-containers/ConfigurationPages/Article/articleListHierarchy";
import AppConfigForm from "page-containers/ConfigurationPages/AppConfig/AppConfigForm";
import CreateAppConfig from "page-containers/ConfigurationPages/AppConfig/CreateAppConfig";
import appConfigList from "page-containers/ConfigurationPages/AppConfig/appConfigList";
import AppCoonfigFormNew from "page-containers/ConfigurationPages/AppConfig/AppConfigFormNew";
import AppConfigFormNew from "page-containers/ConfigurationPages/AppConfig/AppConfigFormNew";
import CreateAppConfigNew from "page-containers/ConfigurationPages/AppConfig/CreateAppConfigNew";
import AppConfigListNew from "page-containers/ConfigurationPages/AppConfig/AppConfigListNew";
import AppConfigMappingList from "page-containers/ConfigurationPages/AppConfig/AppConfigMappingList";
import CreateAppConfigMapping from "page-containers/ConfigurationPages/AppConfig/CreateAppConfigMapping";
import AppConfigBlankForm from "page-containers/ConfigurationPages/AppConfig/AppConfigBlankForm";

const PreventiveMaintenance = lazy(() =>
  import("./page-containers/PreventiveMaintenance/enterPreventiveMaintenance")
);

const Tickets = lazy(() => import("./components/Tickets"));

const BMCStatusPage = lazy(() =>
  import("./page-containers/ConfigurationPages/Site/BMCStatusPage")
);
const SubOrganizationProfile = lazy(() =>
  import("./page-containers/ConfigurationPages/Organization/SubOrganization")
);

const OrganizationTree = lazy(() =>
  import("./page-containers/OtherPages/OrganizationTreePage")
);

const OrgCompleteTree = lazy(() =>
  import("./page-containers/OtherPages/OrgCompleteTreePage")
);

const RatingTree = lazy(() => 
  import("./page-containers/OtherPages/RatingTreePage")
);

const CategoryTree = lazy(() =>
  import("./components/common/elements/CategoryTree")
);

// const Faq = lazy(() => import("./components/Faq"));

const Role = lazy(() =>
  import("./page-containers/ConfigurationPages/Role/Role")
);
const TotalSites = lazy(() =>
  import("./page-containers/OtherPages/TotalSites")
);
const Login = lazy(() => import("./page-containers/Authentication/Login"));
// const Dashboard = lazy(() => import("./page-containers/Dashboard/Dashboard"));

function App(props) {
  const { initialized } = useKeycloak();

  if (!initialized) {
    console.log("initialized ", initialized);
    return <div>Loading...</div>;
  }
  return (
    // <React.StrictMode>
    <Router>
      <React.Suspense fallback={<SpinningLoader />}>
        <ErrorWrapper>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute
              exact
              path="/OrgSelectpage"
              component={OrgSelectpage}
            />

            <SiteWrapper>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute
                exact
                path="/critical-alerts"
                component={CriticalAlerts}
              />
              <PrivateRoute exact path="/info-alerts" component={InfoAlerts} />
              <PrivateRoute
                exact
                path="/configuration/totalsite"
                component={TotalSite2}
              />
              <PrivateRoute
                exact
                path="/configuration/totalsite2"
                component={TotalSite}
              />
              {/* <PrivateRoute exact path="/all-bmc/:state" component={TotalBMC} />
              <PrivateRoute exact path="/all-bmc" component={TotalBMC} />
              <PrivateRoute exact path="/all-bmc1" component={TotalBMC1} />
              <PrivateRoute
                exact
                path="/all-bmc1/:state"
                component={TotalBMC1}
              />
              <PrivateRoute exact path="/all-bmc2" component={TotalBMC2} />
              <PrivateRoute
                exact
                path="/all-bmc2/:state"
                component={TotalBMC2}
              /> */}
              <PrivateRoute exact path="/all-bmc" component={TotalBMC3} />
              <PrivateRoute exact path="/allbmc" component={TotalLiveBMC} />
              <PrivateRoute
                exact
                path="/all-bmc/:state"
                component={TotalBMC1}
              />
              <PrivateRoute exact path="/allalerts" component={AllAlerts} />
              <PrivateRoute
                exact
                path="/allalerts/:state"
                component={AllAlerts}
              />
              <PrivateRoute
                exact
                path="/configuration/totalsites"
                component={TotalSites}
              />
              <PrivateRoute exact path="/totalsites" component={TotalSites} />
              <PrivateRoute exact path="/reports" component={ReportTabs} />
              <PrivateRoute
                exact
                path="/profile"
                component={Profile}
                metaProps={{
                  default: true
                }}
              />
              <PrivateRoute
                exact
                path="/changePassword"
                component={ChangePassword}
              />
              <PrivateRoute
                exact
                path="/preventiveMaintenance/create"
                component={PreventiveMaintenance}
              />
              <PrivateRoute exact path="/map-test" component={Map} />
              <PrivateRoute exact path="/profile/success" component={Profile} />
              <PrivateRoute
                exact
                path="/sitecreatetest"
                component={CreateSite}
              />
              <PrivateRoute
                exact
                path="/organization/profile/:organization"
                component={OrganizationProfile}
              />
              <PrivateRoute
                exact
                path="/totalsites/site-details/:siteId/:siteUUID"
                component={SiteDetails}
              />
              <PrivateRoute
                exact
                path="/totalsites/site-details/:siteId"
                component={SiteDetails}
              />
              <PrivateRoute
                exact
                path="/configuration/organizationlist"
                component={OrganizationList}
              />
              <PrivateRoute
                exact
                path="/configuration/rolelist"
                component={RoleList}
              />
              {/* <PrivateRoute
                exact
                path="/monitoring/chilling graph/bmcgraph"
                component={BMCStatusPage}
              /> */}
              <PrivateRoute
                exact
                path="/bmcgraph/:siteId"
                component={BMCStatusPage}
              />
              <PrivateRoute
                exact
                path="/configuration/site"
                component={CreateSite}
              />
              <PrivateRoute
                exact
                path="/configuration/site/:siteId"
                component={EditSite}
              />
              <PrivateRoute
                exact
                path="/configuration/site/uuid/:uuid"
                component={SiteDetailsByUuid}
              />
              <PrivateRoute
                exact
                path="/configuration/userlist"
                component={UserList}
              />
              <PrivateRoute
                exact
                path="/configuration/organization"
                component={Organization}
              />
              <PrivateRoute
                exact
                path="/configuration/organization/:id"
                component={Organization}
              />
              <PrivateRoute
                exact
                path="/configuration/SuborganizationList/:orgName"
                component={SubOrganization}
              />
              <PrivateRoute
                exact
                path="/configuration/suborganizationprofile"
                component={SubOrganizationProfile}
              />
              <PrivateRoute
                exact
                path="/configuration/suborganizationprofile/:id"
                component={SubOrganizationProfile}
              />
              <PrivateRoute exact path="/configuration/role" component={Role} />
              <PrivateRoute
                exact
                path="/configuration/role/:role"
                component={Role}
              />
              <PrivateRoute
                exact
                path="/configuration/user"
                component={CreateUser}
              />
              <PrivateRoute
                exact
                path="/configuration/user/:userName"
                component={EditUser}
              />
              <PrivateRoute
                exact
                path="/configuration/import-site"
                component={ImportSite}
              />
              <PrivateRoute
                exact
                path="/configuration/import-alert"
                component={ImportAlert}
              />
              <PrivateRoute
                exact
                path="/org-suborg-tree/:organization"
                component={OrganizationTree}
              />
              <PrivateRoute
                exact
                path="/organization-tree"
                component={OrgCompleteTree}
              />
              <PrivateRoute
                exact
                path="/bmc-rating/:bmcId"
                component={RatingGraph}
              />
              <PrivateRoute
                exact
                path="/configuration/checklist-templates-list"
                component={ChecklistTemplateList}
              />
              <PrivateRoute
                exact
                path="/configuration/checklist-templates-create"
                component={CreateChecklistTemplate}
              />
              <PrivateRoute
                exact
                path="/configuration/checklist-templates-edit/:templateId"
                component={EditChecklistTemplate}
              />
              <PrivateRoute
                exact
                path="/configuration/loan-insurance-list"
                component={LoanInsuranceList}
              />
              <PrivateRoute
                exact
                path="/configuration/loan-insurance-create"
                component={CreateLoanInsurance}
              />
              <PrivateRoute
                exact
                path="/configuration/loan-insurance-edit/:productId"
                component={EditLoanInsurance}
              />
              <PrivateRoute
                exact
                path="/configuration/retailer-list"
                component={RetailerList}
              />
              <PrivateRoute
                exact
                path="/configuration/retailer-edit/:id"
                component={EditRetailer}
              />
              <PrivateRoute
                exact
                path="/configuration/import-retailer"
                component={ImportRetailer1}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-list"
                component={AgentList}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-create"
                component={CreateAgent}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-edit/:id"
                component={EditAgent}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-associate/:id/:name"
                component={AgentAssociation}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-criteria"
                component={AgentCriteria}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-criteria-edit/:criteriaId/:agentName"
                component={EditAgentCriteria}
              />
              <PrivateRoute
                exact
                path="/configuration/import-cod"
                component={ImportCOD}
              />
              <PrivateRoute
                exact
                path="/configuration/cod-list"
                component={CODList}
              />
              <PrivateRoute
                exact
                path="/configuration/agent-payment-list"
                component={AgentPaymentList}
              />
              <PrivateRoute
                exact
                path="/configuration/import-agent-payment"
                component={ImportAgentPayment}
              />
              <PrivateRoute
                exact
                path="/preventiveMaintenance/view-records"
                component={PreventiveMaintenanceList}
              />
              <PrivateRoute
                exact
                path="/preventiveMaintenance/view-records/record-details/:checklistName"
                component={viewRecordDetails}
              />
              <PrivateRoute
                exact
                path="/realtimeChart"
                component={RealTimeChart}
              />
              <PrivateRoute
                exact
                path="/totalsites/site-details/:siteId/device-data/:chillingUnitUUID"
                component={DeviceData}
              />
              {/* <PrivateRoute
                exact
                path="/monitoring/totalsites/site-details/:siteId/device-data/:chillingUnitUUID"
                component={DeviceData}
              /> */}
              <PrivateRoute
                exact
                path="/analytics/bmcrating"
                component={analyticBmcRating}
              />
              <PrivateRoute
                exact
                path="/analytics/bmcgraph"
                component={analyticBmcGraph}
              />
              <PrivateRoute
                exact
                path="/analytics/ratingtemplate"
                component={ratingTemplate}
              />
              <PrivateRoute
                exact
                path="/analytics/rating-tree"
                component={RatingTree}
              />
              <PrivateRoute
                exact
                path="/configuration/notification"
                component={TotalNotification}
              />
              <PrivateRoute
                exact
                path="/configuration/createnotification"
                component={CreateNotification}
              />
              <PrivateRoute
                exact
                path="/configuration/editnotification/:notificationId"
                component={EditNotification}
              />
              <PrivateRoute
                exact
                path="/configuration/createproduct"
                component={CreateProduct}
              />
              <PrivateRoute
                exact
                path="/configuration/productlist"
                component={ProductList}
              />
              <PrivateRoute
                exact
                path="/configuration/editproduct/:productId"
                component={EditProduct}
              />
              <PrivateRoute
                exact
                path="/configuration/viewproduct/:productId"
                component={viewProd}
              />
              <PrivateRoute
                exact
                path="/configuration/approve"
                component={Approve}
              />
              <PrivateRoute
                exact
                path="/configuration/approve/category/:categoryId"
                component={ViewCategory}
              />
              <PrivateRoute
                exact
                path="/configuration/approve/product/:productId"
                component={ViewProduct}
              />
              <PrivateRoute
                exact
                path="/configuration/approve/price/:productId"
                component={ViewPrice}
              />
              <PrivateRoute
                exact
                path="/configuration/farmerassociation"
                component={FarmerAssociation}
              />

              <PrivateRoute
                exact
                path="/configuration/criteria"
                component={Criteria}
              />

              <PrivateRoute
                exact
                path="/configuration/editcriteria/dairy/:criteriaId"
                component={EditCriteriaForDairyFarmer}
              />

              <PrivateRoute
                exact
                path="/configuration/editcriteria/selfreg/:criteriaId"
                component={EditCriteriaForSelfRegFarmer}
              />
              
              <PrivateRoute
                exact
                path="/category-tree"
                component={CategoryTree}
              />
              <PrivateRoute
                exact
                path="/configuration/categorytree-display"
                component={CategoryTreeDisplay}
              />

              <PrivateRoute
                exact
                path="/configuration/customergroup"
                component={CustomerGroup}
              />

              <PrivateRoute
                exact
                path="/configuration/create-customergroup"
                component={CreateCustomerGroup}
              />

	      <PrivateRoute
                exact 
                path="/configuration/pbp_create"
                component={CreatePBP}
              />

              <PrivateRoute
                exact 
                path="/configuration/pbp_list"
                component={pbpList}
              />

              <PrivateRoute
                exact
                path="/configuration/retailer-associate-List"
                component={RetailerAgentList}
              />

              <PrivateRoute
                exact
                path="/configuration/associate-edit/:id/:name"
                component={AssociateRetailerAgent}
              />

              <PrivateRoute
                exact
                path="/configuration/retailer-associate-criteria"
                component={RetailerAgentCriteria}
              />
              <PrivateRoute
                exact
                path="/configuration/associate-criteria-edit/:criteriaId/:agentName"
                component={EditRetailerAgentCriteria}
              />

	            <PrivateRoute
                exact
                path="/configuration/item-level-configuration"
                component={ItemLevelConfiguration}
              />

              <PrivateRoute
                exact
                path="/configuration/warehouse-associate/:id/:name"
                component={WarehouseAssociate}
                />

              <PrivateRoute
                exact
                path="/configuration/warehouse-list"
                component={WarehouseList}
              />

              <PrivateRoute
                exact
                path="/configuration/warehouse-edit/:id/:associationId/:name"
                component={WarehouseEdit}
              />

              <PrivateRoute
                exact
                path="/configuration/warehouse-associate-list"
                component={WarehouseAssociateList}
              />

              <PrivateRoute
                exact
                path="/configuration/import-warehouse"
                component={ImportWarehouse}
              />

	            <PrivateRoute
                exact 
                path="/configuration/banner_create"
                component={CreateBanner}
              />

              <PrivateRoute
                exact 
                path="/configuration/banner_list"
                component={bannerList}
              />

              <PrivateRoute
                exact 
                //path="/configuration/article_list"
                //component={articleList}
                path="/configuration/article_listHierarchy"
                component={articleListHierarchy}
              />

              <PrivateRoute
                exact 
                path="/configuration/article_create"
                component={CreateArticle}
              />

              < PrivateRoute
                exact 
                path="/configuration/appConfigBlank_Form"
                component={AppConfigBlankForm}
              />
                                                       
              <PrivateRoute
                exact 
                path="/configuration/appConfig_list"
                component={appConfigList}
              />
              <PrivateRoute
                exact 
                path="/configuration/appConfig_create"
                component={CreateAppConfig}
              />

              <PrivateRoute
                exact 
                // path="/configuration/appConfig_create"
                // component={CreateAppConfig}
                path="/configuration/appConfigNew_list"
                component={AppConfigListNew}
              />
               <PrivateRoute
                exact 
                path="/configuration/appConfigNew_create"
                component={CreateAppConfigNew}
              />

              <PrivateRoute
                exact 
                path="/configuration/appConfigMapping_list"
                component={AppConfigMappingList}
              />
              <PrivateRoute
                exact 
                path="/configuration/appConfigMapping_create"
                component={CreateAppConfigMapping}
              />


              <PrivateRoute component={Tickets} />
              <PrivateRoute exact path="/faq" component={Faq} />
              <PrivateRoute exact path="/formtest" component={FormSample} />
              <PrivateRoute exact path="/500" component={Error500} />
              <PrivateRoute exact path="/401" component={Error401} />
              <PrivateRoute exact path="/403" component={Error403} />
              <PrivateRoute exact path="/503" component={Error503} />
              <PrivateRoute exact path="/404" component={Error404} />
              <PrivateRoute exact path="/noData" component={NoData} />
              <PrivateRoute exact path="/error" component={Error} />
            </SiteWrapper>
          </Switch>
        </ErrorWrapper>
      </React.Suspense>
    </Router>
    // </React.StrictMode>
  );
}

export default App;
