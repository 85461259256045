
import React, { useEffect, useContext, useState, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
//import Table from "components/common/table/DynamicPaginationTable2";
//import Table from "components/common/table/ControlledPaginationTable";
import Table from "components/common/table/ControlledFullPaginationTable";
import PageHeader from "../../../components/layout/PageHeader.js";

import CircularProgress from "@material-ui/core/CircularProgress";
import TemplateForTables from "../../../components/layout/innerPagesTemplate";
import { Grid, Paper } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { notificationService } from "services/notificationServices";
import { provisioningService } from "../../../services/provisioningServices.js";

import { errorContext } from "context/errorContext";
import addDays from "date-fns/addDays";

import "../../../components/Table.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CommonForm from "components/common/form/common-form";
import { bannerService } from "services/bannerService";
import { articleService } from "services/articleService.js";
import { appConfigService } from "services/appConfigService.js";

export default function AppConfigForm({  
values,
page,
handleSubmit

}) {

  const loadedPage = page;

  const { dispatch: errorDispatch } = useContext(errorContext);
  const [product, setProduct] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [fetchTag, setFetchTag] = useState([]);
  const [status, setStatus] = useState("loading");

  const [startDate] = React.useState(addDays(new Date(), -2));
  const [endDate] = React.useState(new Date());
  const [pbpData, setPbpData] = React.useState([]);
  const [fetchTagOnPbp, setFetchTagOnPbp] = React.useState([]);

  const [fetchTagStatus, setFetchTagStatus] = useState("loading");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();
  const createError = useState({});
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("currentUser"));

  const [keyValue, setKeyValue] = useState([]);

  const dropitems = [
    // {
    //   item: <Link to="/configuration/createproduct">Add Product</Link>
    // }
  ];
 
  let viewDropDown = false;

  // let query = useMemo(() => new URLSearchParams(props.location.search), [
  //   props.location
  // ]);

  // function handleSubmit(values, resetForm, setSubmitting) {
  //   //getArticlelist(values);
  //   setSubmitting(false);
  // }

const { t } = useTranslation();  

  function getKeyDefaultValueList() {
    appConfigService.getKeyDefaultValue().then((data) => {
      console.log("KeyDefaultValue", data);
      setKeyValue(data || []);
      setPageInfo(data.pageInfo);      
      setStatus("success");  
  //Assuming data is an array of objects with 'key' properties
    const mapKey = data.map(item => item.key);
    console.log("map Keys:", mapKey);

    const mapValue = data.map(item1 => item1.defaultValue);
    console.log("map Values:", mapValue);
    
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }

  console.log("Key and DefaultValue", keyValue)

  function getFetchTag() {
    bannerService.getFetchTag().then((data) => {
      setFetchTag(data.fetchTags);   // postman key value also passing here
      setFetchTagStatus("success");
      setStatus("success");
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
        setStatus("failed");
      });
  }

  //   function getPbpList(){
  //     productService.getPbpList().then(data =>{
  //       if(data){
  //         setPbpData(data);
  //         setFetchTagStatus("success");
  //       }
  //     });
  //   }

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      //getPbpList();
      getFetchTag();
      getKeyDefaultValueList();
    })
      .catch(error => {
        errorDispatch({
          type: "ERROR",
          error
        });
      });
  }, []);  

  const fields = [    
    {
      type: "text",
      name: "templateName",
      label: t("template_name_Label"),
      placeholder: t("template_name_Label"),
      // option: locale.length > 0
      //   ? locale.map(locale => ({
      //     value: locale.value,
      //     label: locale.name
      //   }))
      //   : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }
    }, 
    // {
    //   name:"key",
    //   option: keyValue.length > 0
    //     ? keyValue.map(item => ({
    //       key: item.key
          
    //     }))
    //     : [],
    // }
  ];

  const formikForm = React.useRef(null);

  let validations = {    
    templateName: Yup.string().required(t("appConfig_Form_Validation_TemplateName"))
  };

  function handleDeleteRow(rowIndex) {
    const updatedData = keyValue.filter((_, index) => index !== rowIndex); // Remove the row based on index
    setKeyValue(updatedData); // Update the state to reflect the deleted row
  }  

  const columns = React.useMemo(
    () => [
      {
        Header: t("Key"),
        accessor: "key"
      },
      {
        Header: t("Value"),
        accessor: "defaultValue",
        Cell: ({ row, column, updateData, handleSubmit }) => {
          const [defaultValue, setDefaultValue] = useState(row.original.defaultValue);
  
          const handleChange = (e) => {
            setDefaultValue(e.target.value);
          };
  
          const handleSave = () => {
            console.log("Modified Value:", defaultValue);
  
            // Ensure the updateData function is being called properly
            if (updateData) {
              // Update data only if the value has changed
              if (defaultValue !== row.original.defaultValue) {
                updateData(row.index, { ...row.original, defaultValue });
                
                // After updating, trigger handleSubmit with the updated data
                if (handleSubmit) {
                  handleSubmit({ ...row.original, defaultValue });
                } else {
                  console.error("handleSubmit is not defined");
                }
              }
            } else {
              console.error("updateData is not defined");
            }
  
            // Assuming keyValue is the state you want to update with the new defaultValue
            if (keyValue !== undefined) {
              setKeyValue(prevKeyValue => {
                const updatedKeyValue = prevKeyValue.map(item => 
                  item.key === row.original.key
                    ? { ...item, defaultValue }
                    : item
                );
                return updatedKeyValue;
              });
            } else {
              console.error("keyValue state is not defined");
            }
          };
  
          return (
            <div>
              <input
                type="text"
                value={defaultValue}  // Changed to value instead of defaultValue for controlled component
                onChange={handleChange}
                onBlur={handleSave} // Save on blur (focus out)
                onKeyDown={(e) => { // Optional: Save on Enter key press
                  if (e.key === "Enter") {
                    handleSave();
                  }
                }}
              />
              {/* <button onClick={handleSave}>Save</button> Added Save Button */}
            </div>
          );
        }
      },
      // {
      //   Header: t("appConfig_List_Edit_Label"),
      //   Cell: ({ row }) => {         
      //     return (
      //       <IconButton onClick={() => { () => handleUpdateRow(row.index) }}>
      //         <i className="fa fa-edit" aria-hidden="true"></i>
      //       </IconButton>
      //     );
      //   }
      // },
      {
        Header: t("appConfig_List_Delete_Label"),
        Cell: ({ row }) => {
          return (
            <IconButton onClick={() => handleDeleteRow(row.index)}>
              <i className="fa fa-trash" aria-hidden="true"></i>
            </IconButton>
          );
        }
      }
    ],
    [keyValue]
  );

  keyValue.forEach(item => {
    if (item.hasOwnProperty('defaultValue')) {
      item['value'] = item['defaultValue']; // Set new key
      //delete item['defaultValue']; // Remove old key
    }
  });

  const data = React.useMemo(() => [...keyValue], [keyValue]);

  return (

    <Grid container>
    <Grid item xs={12}>
      <PageHeader
        title="Device Configuration Template"
        breadcrumbs={[
          { label: "Dashboard", link: "/dashboard" },
          { label: "Configuration" },
          { label: "Device Configuration Template" }
        ]}
      />
    </Grid>
    <Paper
      elevation={2}
      style={{
        padding: "2em",
        width: "100%"
      }}
    >
  {status === "success" ? (
        // <Grid
        //   container
        //   justify="center"
        //   alignItems="center"
        //   style={{ height: "50vh" }}
        // >
        //  <CircularProgress />
        // </Grid>
        // <Table
        //   columns={columns} 
        //   data={data}
        //   needDropdown={viewDropDown}
        //   dropItems={dropitems}
        //   hidePagination={true}
        // />
        <></>
      ) : null}
      {fetchTagStatus === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
          <Grid item>
          {loadedPage == "add" &&
          <CommonForm
            fields={fields}              
            submitLabel={t("site_Form_Submit_Label")}
            submittingLabel={t("site_Form_Submitting_Label")}
            initialValues={values}              
            validationSchema={Yup.object().shape({ ...validations })}
            validateOnBlur={true}                           
            enableReinitialize
            validateOnChange={true} 

            onSubmit={(values, { resetForm, setSubmitting }) => {
            console.log('onSubmit',values.isActive, values);
            const updatedValues = { ...values, keyValue };
            handleSubmit(updatedValues, resetForm, setSubmitting);
            }}
            formikRef={formikForm}             
            buttonSize="3"
            buttonPosition="right"  
          />            
        }
        <Grid item xs={12} style={{ marginTop: '20px' }}></Grid>
        <Table
          columns={columns} 
          data={data}
          needDropdown={viewDropDown}
          dropItems={dropitems}
          hidePagination={true}
        />
        </Grid>
        )}     
    </Paper>

  </Grid>
  );
};
