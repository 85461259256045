import React, { useState, useContext, useEffect, useCallback } from "react";
import * as Yup from "yup";
import PageHeader from "components/layout/PageHeader";
import CommonForm from "components/common/form/common-form";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import "components/responsive.scss";
import "../configuration.scss";
import "../../../components/responsive.scss";
import { useTranslation } from "react-i18next";
import { errorContext } from "context/errorContext";
import { bannerService } from "services/bannerService";

export default function AppConfigFormNew({
  values,
  //appType,  
  handleSubmit,
  page  

}) {
  const loadedPage = page;
  const { t } = useTranslation();
  const formikForm = React.useRef(null);  
  const [app_Type,setApp_Type]=useState("");
  
  useEffect(() => {
      bannerService.getBannerOptions('APP_TYPE').then(data => {
        if (data) {
          setApp_Type(data);
        }
      })
    },[]);

  let validations = {

    key: Yup.string()
      .matches(/^[a-zA-Z0-9]*$/, t("appConfigForm_New_Validation_key_No_Spaces"))
      .required(t("appConfigForm_New_Validation_key")),
    value: Yup.string()
      .matches(/^\S*$/, t("appConfigForm_New_Validation_key_No_Spaces"))
      .required(t("appConfigForm_New_Validation_value")),
    defaultValue: Yup.string()
      .matches(/^\S*$/, t("appConfigForm_New_Validation_key_No_Spaces"))
      .required(t("appConfigForm_New_Validation_defaultValue")),
    appType: Yup.string().required(t("appConfigForm_New_Validation_appType"))

  }; 

  const fields = [
    {
      type: "text",
      name: "key",
      label: t("appConfigForm_New_key"),
      placeholder: t("appConfigForm_New_key"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "value",
      label: t("appConfigForm_New_value"),
      placeholder: t("appConfigForm_New_value"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "text",
      name: "defaultValue",
      label: t("appConfigForm_New_defaultValue"),
      placeholder: t("appConfigForm_New_defaultValue"),
      grid: {
        xs: 12,
        sm: 3,
        className: ""
      }
    },
    {
      type: "select",
      name: "appType",      
      label: t("appConfigForm_New_appType"),
      placeholder: t("appConfigForm_New_appType"),
      option:
      app_Type.length > 0
          ? app_Type.map(app_Type => ({
              value: app_Type,
              label: app_Type
            }))
          : [],
      grid: {
        xs: 12,
        sm: 3,
        className: "mb-2"
      }, 
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="App Configuration"
          breadcrumbs={[
            { label: "Dashboard", link: "/dashboard" },
            { label: "Configuration" },
            { label: "App Configuration" }
          ]}
        />
      </Grid>

      <Paper
        elevation={2}
        style={{
          padding: "2em",
          width: "100%"
        }}
      >
        <Grid item>
          {loadedPage == "add" &&
            <CommonForm
              fields={fields}
              submitLabel={t("site_Form_Submit_Label")}
              submittingLabel={t("site_Form_Submitting_Label")}
              initialValues={values}
              validationSchema={Yup.object().shape({ ...validations })}
              validateOnBlur={true}
              enableReinitialize
              validateOnChange={true}              
              onSubmit={(values, { resetForm, setSubmitting }) => {
                handleSubmit(values, resetForm, setSubmitting);
              }}

              formikRef={formikForm}
              buttonSize="3"
              buttonPosition="right"
            />
          }
        </Grid>
      </Paper>      
    </Grid>
  );
}
