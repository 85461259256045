import React, { useEffect, useContext, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { provisioningService } from "../../../services/provisioningServices.js";
import Table from "components/common/table/DynamicPaginationTable2.js";
import PageHeader from "components/layout/PageHeader";
import Grid from "@material-ui/core/Grid";
import { errorContext } from "context/errorContext";
import { UseTranslation, useTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import { appConfigService } from "services/appConfigService.js";
import { Link } from "react-router-dom";

const AppConfigListNew = props => {

  const { t } = useTranslation();

  const [status, setStatus] = React.useState("loading");
  const [pageInfo, setPageInfo] = React.useState({});  
  const {dispatch: errorDispatch } = useContext(errorContext);
  const [keyValue, setKeyValue] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("Key"),
        accessor: "key",
      },
      {
        Header: t("Value"),
        accessor: "defaultValue",
      },     
    ],
    []
  );

  useEffect(() => {
    provisioningService.verifyOtpNew('1313131313').then((res) => {
      const bearerTokenFromFarmerCoreService = localStorage.getItem("bearerToken");
      const organizationId = JSON.parse(localStorage.getItem("orgId"));
      getKeyDefaultValueList();
    })
    .catch(error => {
      errorDispatch({
        type: "ERROR",
        error
      });
    });
  }, []);

  const data = React.useMemo(() => [...keyValue], [keyValue]);

   function getKeyDefaultValueList() {
      appConfigService.getKeyDefaultValue().then((data) => {
        console.log("KeyDefaultValue", data);
        setKeyValue(data || []);
        setPageInfo(data.pageInfo);      
        setStatus("success");  
    //Assuming data is an array of objects with 'key' properties
      const mapKey = data.map(item => item.key);
      console.log("map Keys:", mapKey);
  
      const mapValue = data.map(item1 => item1.defaultValue);
      console.log("map Values:", mapValue);
      
      })
        .catch(error => {
          errorDispatch({
            type: "ERROR",
            error
          });
          setStatus("failed");
        });
    }

    const dropitems = [
      {
        item: (
          <Link to="/configuration/appConfigNew_create">
           {t("appConfigNew_Add_Label")}
          </Link>
        )
      }
    ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageHeader
          title="App Configuration List"//{t("pbp_HeaderList_Label")}
         /* breadcrumbs={[
            { label: t("retailer_Breadcrumb_Dashboard_Label"), link: "/dashboard" },
            { label: t("retailer_Breadcrumb_Config_Label") },
            { label: t("retailer_Breadcrumb_Retailer_Label") }
          ]} */
        />
      </Grid>

      <Paper
         elevation={2}
         style={{
         padding: "2em",
         width: "100%"
        }}
      >
    
      { status === "loading" ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "50vh" }}
        >
         <CircularProgress />
        </Grid>
      ) : (
          <Table
            columns={columns}
            data={data}
            needDropdown={true}
            dropItems={dropitems}
          />
      )  } 

      </Paper>
    </Grid>
  );
};

export default AppConfigListNew;
