import { func } from "prop-types";
import { utilityServices } from "./utilityServices";
import { language } from "i18next";

function getToken() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    const token = user && user.jwtAccessToken;
    return token;
}

async function getKeyDefaultValue(){  
  const url = `${process.env.REACT_APP_GET_APP_CONFIG_MAPPING_LIST}`;  
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),      
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`     
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function AppConfigCreate(values){
  const url = process.env.REACT_APP_APPCONFIG_CREATE
  const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    authToken: localStorage.getItem("idToken"),
    "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
    Authorization:`Bearer ${localStorage.getItem("bearerToken")}`    
  },
  body: JSON.stringify(values)
};
try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

async function getAppConfigList(){  
  const url = process.env.REACT_APP_APPCONFIG_GET_TEMPLATE_NAMES;  
  const requestOptions = {
    method: "GET",
    headers: {
     // "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),      
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`,
      //fetchTag: (fetchtag) // Passing dropdownfield
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function deleteTempName(tempName){ 
  //const url = `${process.env.REACT_APP_GET_BANNER_LIST}?appType=${apptype}&locale=${language}`; 
  const url = `${process.env.REACT_APP_APPCONFIG_DELETE_TEMPLATE_NAMES}?name=${tempName}`;  
  
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authToken: localStorage.getItem("idToken"),      
      "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
      Authorization:`Bearer ${localStorage.getItem("bearerToken")}`      
    },
    body: JSON.stringify()
  };
  try {
    const response = await fetch(url, requestOptions);
    if (response.ok) {
      return await response.json();
    } else {
      throw response;
    }
  } catch (error) {
    let err = {
      status: error.status,
      error: !!error.body ? await error.json() : true
    };
    throw err;
  }
}

async function AppConfigCreateNew(values){
  const url = process.env.REACT_APP_APPCONFIG_CREATE_NEW
  const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    authToken: localStorage.getItem("idToken"),
    "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
    Authorization:`Bearer ${localStorage.getItem("bearerToken")}`    
  },
  body: JSON.stringify(values)
};
try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

async function GetAppConfigMappingList(values){
  const url = process.env.REACT_APP_GET_APP_CONFIG_MAPPING_LIST_NAMES
  const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    authToken: localStorage.getItem("idToken"),
    "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
    Authorization:`Bearer ${localStorage.getItem("bearerToken")}`    
  },
  body: JSON.stringify(values)
};
try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

async function AppConfigCreateMapping(values){
  const url = process.env.REACT_APP_APPCONFIG_MAPPING_CREATE
  const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    authToken: localStorage.getItem("idToken"),
    "Api-Key": "e87MPVwlgORoG9J8Pod4j7L5p0eQeU",
    Authorization:`Bearer ${localStorage.getItem("bearerToken")}`    
  },
  body: JSON.stringify(values)
};
try {
  const response = await fetch(url, requestOptions);
  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
} catch (error) {
  let err = {
    status: error.status,
    error: !!error.body ? await error.json() : true
  };
  throw err;
}
}

export const appConfigService = { 
  getKeyDefaultValue,    
  AppConfigCreate,
  getAppConfigList,
  deleteTempName,
  AppConfigCreateNew,
  GetAppConfigMappingList,
  AppConfigCreateMapping

};
