import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter
} from "react-table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import "../../Table.scss";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Table({ columns, data, hidePagination = false }) {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows, // Instead of `page`, we use all rows
    state: { globalFilter },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false
    },
    useGlobalFilter,
    useSortBy
  );

  const history = useHistory();
  const location = useLocation();
  let query = useMemo(() => new URLSearchParams(location.search), [location]);
  const { t } = useTranslation();

  React.useEffect(() => {
    // Removed pagination logic based on URL query params
  }, [query]);

  function handlePageSize(e) {
    // No longer needed since we're displaying all data
  }

  return (
    <>
      <div style={{ overflow: "auto" }}>
        <table {...getTableProps()} className="data-table">
          <thead>
            {(headerGroups || []).map((headerGroup, hGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={hGroupIndex}>
                {headerGroup.headers.map((column, i) => {
                  if (column.Header === "Actions") {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        key={column.header + i.toString()}
                        colSpan={2}
                        width={120}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  }
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="alert-header"
                      key={column.header + i.toString()}
                      width={column.width !== 150 ? column.width : 120}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    if (cell.column.Header === "Actions") {
                      return cell.render("Cell");
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={i + j + 1}
                        width={
                          cell.column.width !== 150 ? cell.column.width : 120
                        }
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!hidePagination && (
        <Grid
          container
          justify="space-between"
          className="pagination data-table-pagination"
          style={{ padding: "1em 0em 1em 0em" }}
        >
          <Grid
            item
            container
            xs={12}
            md={6}
            justify="flex-start"
            alignItems="center"
            className="page-btm-data"
          >
            <span>
              {t("pagination_Page")} &nbsp;
              <strong>1</strong> {/* Display only a single page */}
            </span>
            &nbsp;
            {/* Remove the page size selection */}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Table;